* {
    box-sizing: border-box;
    /* This is a good habit to get into */
}

.outer {
    display: flex;
    /* the main rule we're focusing on */
}

.flex-child {
    flex-grow: 1;
    /* tells the 2 children to fill as much space as they can equally */
}


/* for visual aid, not necessarily relevant to question */

#product-container #productAd button {
    color: var(--red);
}

.div {
    padding: 5px;
    margin: 1px;
}

#productAd {
    /* padding for the test ad */
    padding-top: 4%;
    padding-bottom: 4%;
}

#productAd button {
    background-color: var(--red);
}

#textContainerProduct {
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
}

#productDetails p {
    font-style: italic;
}

#product-container p {
    color: var(--brown);
}

#productTitle p {
    font-weight: bold;
}

#designerName h4 {
    font-weight: bold;
    margin-top: 2%;
}

#product-manufacturing {
    margin-top: 2%;
}

#textContainerProduct p {
    text-align: justify
}

#productButtonContainer button {
    color: white;
    background-color: var(--red);
    border: none;
    height: 50px;
}

#productButtonContainer {
    gap: 3%;
}

#select h5 {
    padding-bottom: 5%;
    font-weight: bold;
}

#productSelect {
    text-align: center;
    width: 100%;
    padding-top: 1%;
    padding-bottom: 1%;
    color: var(--brown);
    border-width: 2px;
    border-color: var(--brown);
}

#product-container .side-img img:hover {
    cursor: pointer;
}

#productName h5 {
    font-weight: bold;
    color: var(--red);
}

#productImgContainer {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
}

@media screen and (max-width:2560px) and (min-width:1300px) {
    #bigContainer {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
    }
    .side-img-vitelli img {
        width: 35%;
    }
    .side-img-faux img {
        width: 85%;
    }
    #div-r {
        width: 50%;
        height: 50%;
    }
    #main {
        height: 200%;
        width: 80%;
        object-fit: contain;
        object-position: top;
    }
    #selection-container {
        width: 30%;
    }
}

@media screen and (min-width:1431px) and (max-width:2510px) {
    #bigContainer {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
    }
    .side-img-vitelli img {
        width: 65%;
    }
    .side-img-faux img {
        width: 130%;
    }
    #div-r {
        width: 100%;
        height: 100%;
    }
    #main {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: top;
    }
    .PETER {
        width: 130% !important;
    }
    .Vitelli {
        width: 70% !important;
    }
    #selection-container {
        width: 40%;
    }
    #productButtonContainer {
        gap: 0%;
    }
    #productButtonContainer #button2 {
        margin-left: 6%;
    }
    #productSelect {
        text-align: center;
        width: 77%;
        padding-top: 1%;
        padding-bottom: 1%;
        color: var(--brown);
        border-width: 2px;
        border-color: var(--brown);
    }
}

@media screen and (max-width:1430px) and (min-width:1000px) {
    #bigContainer {
        /* width: 20%; */
        width: 90%;
        margin-left: 0;
        margin-right: 0;
    }
    .side-img-vitelli img {
        width: 35%;
    }
    .side-img-faux img {
        width: 95%;
    }
    #div-r {
        width: 100%;
        height: 100%;
    }
    #main {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: top;
    }
    .PETER {
        width: 130% !important;
    }
    .Vitelli {
        width: 100% !important;
    }
    #selection-container {
        width: 40%;
    }
    #productButtonContainer {
        gap: 0%;
    }
    #productButtonContainer #button2 {
        margin-left: 6%;
    }
}

@media screen and (max-width:999px) {
    #bigContainer {
        /* width: 20%; */
        width: 90%;
        margin-left: 0;
        margin-right: 0;
    }
    .side-img-vitelli img {
        width: 35%;
    }
    #div-r {
        width: 100%;
        height: 100%;
    }
    #main {
        height: 80%;
        width: 100%;
        object-fit: contain;
        object-position: top;
    }
    #selection-container {
        width: 30%;
    }
    .single-vitelli {
        width: 40%;
    }
    .single-faux {
        width: 80%;
    }
    #product-container .swiper-button-next {
        right: 5%;
    }
    #product-container .swiper-button-prev {
        left: 5%;
    }
}

@media screen and (max-width:750px) {
    #selection-container {
        width: 50%;
    }
    #productButtonContainer {
        gap: 10%;
    }
}

@media screen and (max-width:500px) {
    #selection-container {
        width: 80%;
    }
    #productButtonContainer {
        gap: 10%;
    }
}