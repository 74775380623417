#archetypesDuck {
    width: 40%;
}

#archName {
    color: var(--purple);
    font-weight: bold;
}

#shopProfile {
    padding-bottom: 5%;
    padding-top: 5%;
}

#archetypesContainer button {
    background-color: var(--purple);
    color: white;
}

#archetypesContainer h5 {
    color: var(--purple);
}

#profile-button {
    padding-bottom: 5%;
    padding-top: 5%;
}

@media screen and (max-width:1000px) {
    #profile-swiper .swiper-button-next,
    #profile-swiper .swiper-button-prev {
        top: 30%;
    }
    #archetypesContainer {
        margin-top: 5%;
    }
}

@media screen and (max-width:800px) {
    #profile-swiper .swiper-button-next,
    #profile-swiper .swiper-button-prev {
        top: 25%;
    }
}

@media screen and (max-width:800px) {
    #profile-swiper .swiper-button-next,
    #profile-swiper .swiper-button-prev {
        top: 20%;
    }
}

@media screen and (max-width:560px) {
    #profile-swiper .swiper-button-next,
    #profile-swiper .swiper-button-prev {
        top: 15%;
    }
}

@media screen and (max-width:414px) {
    #profile-swiper .swiper-button-next,
    #profile-swiper .swiper-button-prev {
        top: 12%;
    }
}

@media screen and (max-width:330px) {
    #profile-swiper .swiper-button-next,
    #profile-swiper .swiper-button-prev {
        top: 8%;
    }
}

#profile-swiper .swiper-button-next,
#profile-swiper .swiper-button-prev {
    color: var(--red);
}

.test-results {
    width: 100%;
}

.test-results p {
    margin-left: 5%;
    margin-right: 5%;
    text-align: justify;
}

p {
    color: var(--brown);
}

#menuTop {
    gap: 2%;
}

#menuTop h4 {
    font-weight: bold;
}

a {
    color: grey;
    text-decoration: none;
}

a:hover {
    color: black;
}

#archetypesContainer {
    /* height: 78vh; */
    min-height: 100vh;
}

#profileLogout {
    position: relative;
    left: 0%;
}

@media screen and (max-width: 3024px) {
    #orderImg {
        width: 30%;
        height: 80%;
        object-fit: contain;
    }
    #myInformationContainer {
        padding-left: 2%;
        height: 78vh;
        padding-top: 5%;
    }
    #myInformationContainer h5 {
        padding-top: 4%;
        padding-bottom: 4%;
    }
}


/* #OrderContainer {
    padding-right: 10%;
} */

#orderDescriptionContainer {
    text-align: left;
}

#ordersContainer {
    padding-left: 2%;
    padding-top: 3%;
    min-height: 100vh;
}

#ordersContainer h4 {
    padding-top: 2%;
}

#ordersContainer h3 {
    padding-top: 1%;
    font-size: large;
    font-weight: bold;
}

#ordersContainer h4 {
    font-weight: bold;
}

#OrderTxt {
    padding-left: 5%;
    text-align: left;
}

#OrderTxt p {
    margin: 0;
}

#orderOne,
#orderTwo {
    gap: 2%;
}

#orderOne p,
h5 {
    margin: 0;
}

#orderOne {
    gap: 8%;
}

#orderOne p,
#orderTwo p {
    width: 100%;
}

#orderOne h5 {
    font-weight: bold;
}

#wishlistContainer {
    /* height: 78vh; */
    min-height: 100vh;
}

#wishlistItems img {
    width: 50%;
    height: 80%;
}

#myInformationContainer {
    padding-left: 2%;
    /* height: 78vh; */
    min-height: 100vh;
}

#myInformationContainer h4 {
    font-weight: bold;
}


/* #myInformationContainer h5 {
    padding-top: 4%;
    padding-bottom: 4%;
} */

#namesContainer {
    gap: 10%;
}

#name {
    padding-left: 5%;
}