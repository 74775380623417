@media screen and (max-width: 3024px) {
    #loginContainerParent {
        padding-top: 4%;
    }
    #loginContainer #buttonDiv {
        padding-left: 5%;
        padding-right: 5%;
    }
}

#bottomFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

#loginContainer {
    text-align: left;
    min-height: 100vh;
}

#loginContainer input {
    border-radius: 18px;
    border: 1px solid grey;
    line-height: 200%;
    padding-left: 8%;
    width: 100%;
}

#loginContainer #input1 {
    padding-top: 5%;
    padding-bottom: 10%;
}

#loginContainer #input2 {
    padding-bottom: 10%;
}

#loginContainer h3 {
    padding-left: 30%;
}


/* #loginContainer #buttonDiv {
    padding-left: 10%;
} */

#loginContainer button {
    border: none;
    color: white;
    background-color: var(--brown);
    width: 100%;
    height: 35px;
}

#loginContainer #register {
    padding-top: 5%;
    text-align: right;
}

#loginContainer a:hover {
    text-decoration: underline;
    text-decoration-color: var(--brown);
}

#errorLogin {
    padding-top: 1%;
}

#forgottenPassword {
    padding-top: 5%;
    cursor: pointer;
}

#forgottenPassword:hover {
    text-decoration: underline;
    text-decoration-color: var(--brown);
}