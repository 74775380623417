ul {
    list-style-type: none;
}

#shopContainer h2 {
    color: var(--purple)
}

#testContainerShop {
    padding-left: 30%;
    width: 50%;
}

#singleProduct {
    text-align: left;
    overflow: hidden;
}

#shopPageContainer {
    padding-bottom: 3%;
    min-height: 100vh;
}

#filterButton {
    border: none;
    background-color: transparent;
    padding-left: 0;
    cursor: pointer;
}

#innerUl h5:hover {
    text-decoration: underline;
    text-decoration-color: var(--brown);
    cursor: pointer;
}

#innerUl h5 {
    margin-top: 5%;
    color: var(--purple);
}

#innerUl label input {
    display: none;
}

#innerUl label span {
    height: 10px;
    width: 10px;
    display: inline-block;
    position: relative;
    padding-left: 15%;
}

#innerUl label:hover {
    cursor: pointer;
}

#filters-div {
    gap: 5%;
    align-items: center;
}

@media screen and (max-width:1420px) {
    #filters-div h5 {
        font-size: 1.2vw !important;
    }
}


/* @media screen and (min-width:1420px) {
    #filters-div h5 {
        font-size: 1vw !important;
    }
} */

#categoryUl,
#genderUl,
#innerUl {
    display: none;
}

#genderUl li label,
#categoryUl li label {
    width: 100%;
}

.menu #genderLi,
.menu #categoryLi {
    margin-top: 5%;
    margin-bottom: 5%;
}

[type=checkbox]:checked+span:before {
    content: '\2714';
}

.menu {
    display: block;
    position: relative;
    width: 15%;
    /* min-width: 151px; */
}

.menu ul {
    height: 0;
    left: 0;
    opacity: 0;
    transition: all .5s ease;
    top: 35px;
    width: 100%;
    list-style-type: none;
    text-align: left;
    padding-left: 1%;
}

.menu ul li {
    color: var(--brown);
    display: block;
    padding-bottom: 3%;
}

#firstLi {
    text-align: left;
}

input#menu {
    display: none;
}

.icon {
    cursor: pointer;
    height: 24px;
    padding: 14px;
    width: 24px;
    color: var(--brown);
}

label.icon {
    z-index: 200;
}

.icon .menu,
.icon .menu::before,
.icon .menu::after {
    background: var(--brown);
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: background ease .3s, top ease .3s .3s, transform ease .3s;
    width: 20px;
}

.icon:hover .menu,
.icon:hover .menu::before,
.icon:hover .menu::after {
    background: var(--brown);
}

.icon .menu::before {
    top: -6px;
}

.icon .menu::after {
    top: 6px;
}

#menu:checked+.icon .menu {
    background: transparent;
}

#menu:checked+.icon .menu::before {
    transform: rotate(45deg);
}

#menu:checked+.icon .menu::after {
    transform: rotate(-45deg);
}

#menu:checked+.icon .menu::before,
#menu:checked+.icon .menu::after {
    top: 0;
    transition: top ease .3s, transform ease .3s .3s;
}

.arrow-side {
    transform: rotate(-45deg);
    width: 10%;
    padding-bottom: 2px;
    padding-left: 4px;
    transition: 0.5s;
}

.arrow-down {
    transform: rotate(45deg);
    width: 10%;
    padding-bottom: 2px;
    padding-left: 4px;
    margin-top: -6px;
    transition: 0.5s;
}

@media screen and (min-width:1430px) {
    .arrow-side {
        transform: rotate(-45deg);
        width: 8%;
        padding-bottom: 2px;
        padding-left: 4px;
        transition: 0.5s;
    }
    .arrow-down {
        transform: rotate(45deg);
        width: 8%;
        padding-bottom: 2px;
        padding-left: 4px;
        margin-top: -6px;
        transition: 0.5s;
    }
}

@media screen and (min-width:1624px) {
    .arrow-side {
        transform: rotate(-45deg);
        width: 6%;
        padding-bottom: 2px;
        padding-left: 4px;
        transition: 0.5s;
    }
    .arrow-down {
        transform: rotate(45deg);
        width: 6%;
        padding-bottom: 2px;
        padding-left: 4px;
        margin-top: -6px;
        transition: 0.5s;
    }
}

@media screen and (min-width:2000px) {
    .arrow-side {
        transform: rotate(-45deg);
        width: 5%;
        padding-bottom: 2px;
        padding-left: 4px;
        transition: 0.5s;
    }
    .arrow-down {
        transform: rotate(45deg);
        width: 5%;
        padding-bottom: 2px;
        padding-left: 4px;
        margin-top: -6px;
        transition: 0.5s;
    }
}

@media screen and (min-width:2300px) {
    .arrow-side {
        transform: rotate(-45deg);
        width: 4%;
        padding-bottom: 2px;
        padding-left: 4px;
        transition: 0.5s;
    }
    .arrow-down {
        transform: rotate(45deg);
        width: 4%;
        padding-bottom: 2px;
        padding-left: 4px;
        margin-top: -6px;
        transition: 0.5s;
    }
}