/* Normal nav css here */

@media screen and (max-width:2600px) and (min-width:764px) {
    .container {
        max-width: 2500px !important;
    }
    #center-nav-main {
        padding-right: 19% !important;
    }
}

@media screen and (max-width:2000px) {
    .container {
        max-width: 2000px !important;
    }
}

@media screen and (max-width:1400px) {
    .container {
        max-width: 1500px !important;
    }
}

@media (min-width:771px) {
    /* screen sizes 1024 to 1366 */
    #center-nav-main {
        padding-right: 24%;
    }
    #right-nav-main {
        position: absolute;
        right: 3%;
    }
    /* #navContainer {
        margin-left: 0;
    } */
    .nav-item {
        padding-left: 7px;
    }
    #right-nav-main li {
        clear: both;
        display: inline-block;
        /* overflow: hidden; */
        white-space: nowrap;
    }
    #profileContainer {
        padding-right: 5%;
    }
    #center-nav-home {
        padding-right: 0%;
    }
    #center-home-nav a {
        font-size: 200%;
    }
    #right-nav-home {
        position: absolute;
        right: 0%;
    }
    #center-ul .center-nav-item {
        padding-left: 10%;
    }
    #right-ul {
        align-items: center;
    }
    #profileContainer {
        display: flex;
        padding-right: 8%;
    }
    #profileContainer #profile-li {
        padding-left: 9%;
    }
    #logout-li {
        /* change cursor */
        cursor: pointer;
    }
    .center-nav-item {
        width: 100%;
    }
}

@media (max-width:1100px) and (min-width:768px) {
    #center-nav-main {
        padding-right: 30%;
    }
}

#navLogo {
    max-height: 150px;
    width: 10% !important;
    float: left;
}

@media (max-width:767px) {
    #navLogo {
        max-height: 150px;
        width: 30% !important;
        float: left;
    }
}

@media (max-width:767px) {
    #center-nav-main {
        float: left;
        align-content: flex-start;
        align-items: flex-start;
    }
    #right-nav-main #profileContainer li,
    #right-nav-main #profileContainer li a {
        margin-top: 10%;
        margin-bottom: 10%;
    }
    #right-nav-main {
        text-align: left;
    }
    #center-ul li,
    #right-nav-main li {
        margin-top: 2%;
        margin-bottom: 2%;
    }
    #menuTop {
        flex-direction: column;
        float: right;
        align-items: flex-end;
    }
}

@media screen and (max-width:414px) {
    /* #menuTop {
        padding-top: 2%;
    } */
    #menuTop h2 {
        font-size: 130%;
    }
    #right-nav-main {
        align-items: flex-start;
    }
}

@media screen and (max-width:378px) {
    #menuTop h2 {
        font-size: 100%;
    }
    #menuTop {
        padding-top: 3%;
    }
}

@media screen and (max-width:336px) {
    #menuTop h2 {
        font-size: 80%;
    }
    #menuTop {
        padding-top: 3%;
    }
}

#right-nav-main #logout-li {
    cursor: pointer;
}

#center-ul {
    align-items: flex-start;
}

.center-nav-item a {
    white-space: nowrap;
}

#basic-navbar-nav {
    justify-content: center;
}

nav {
    height: 100%;
    background-color: var(--yellow);
}

.navbar {
    width: 100vw;
}

.navbar-brand {
    /* width: 10%; */
    left: 0%;
}


/* profile nav css below */

#menuTop h2:hover {
    color: var(--purple);
}