.homePage h4 {
    padding-top: 1%;
    padding-bottom: 1%;
    color: var(--red);
    margin-left: 5%;
    margin-right: 5%;
}

.homePage #testAdContainer button {
    background-color: var(--red);
}

#featuredContainer {
    padding-top: 5%;
}

.homePage img:not(#logo) {
    width: 100%;
}


/* #featuredImgContainer {
    width: 55%;
    gap: 2%;
} */

#arch-swiper .swiper-button-next,
#arch-swiper .swiper-button-prev {
    color: var(--red);
}

#featured-swiper .swiper-button-next,
#featured-swiper .swiper-button-prev {
    color: var(--red);
    top: 25%;
}

#featuredImgContainer div {
    color: var(--brown);
}

#featuredImgContainer {
    margin-left: 5%;
    margin-right: 5%;
}

#featuredImgContainer h4 {
    color: var(--purple);
    font-weight: bold;
}

#featuredImgContainer h4,
#featuredImgContainer p {
    font-size: 1vw;
}

#archetypes-grid img {
    height: 100%;
}

#archetypes-grid img:hover {
    cursor: pointer;
}

#featuredText {
    padding-right: 5%;
    width: 25%;
    color: var(--brown)
}

#featuredText h2 {
    color: var(--red)
}

#homePage h2,
h4 {
    color: var(--purple);
}

#archetypes-grid h5 {
    position: relative;
    color: var(--red);
    z-index: 1000;
    bottom: 8%;
    left: -32%;
    display: inline-block;
    font-weight: bold;
}

#bottomArch h5 {
    bottom: 6%;
}

.featured-component-div {
    width: 50%;
}

@media screen and (max-width: 1366px) {
    /* my monitor screen size */
    #topBlogPage {
        width: 86%;
    }
    #bottomContainerText {
        position: absolute;
        right: -38%;
        bottom: 1%;
    }
    #bottomContainerImg {
        width: 100%;
    }
}

@media screen and (max-width:1269px) {
    #archetypes-grid h5 {
        bottom: 8%;
        left: -25%;
    }
    #bottomContainerImg {
        width: 90%;
    }
}

@media screen and (max-width:1020px) {
    #archetypes-grid h5 {
        bottom: 8%;
        left: -28%;
        font-size: 100%;
    }
    #featuredImgContainer h4,
    #featuredImgContainer p {
        font-size: 2vw;
    }
}

@media screen and (max-width:770px) {
    #archetypes-grid h5 {
        bottom: 8%;
        left: -24%;
        font-size: 90%;
    }
}

@media screen and (max-width:700px) {
    #archetypes-grid h5 {
        bottom: 8%;
        left: -24%;
        font-size: 90%;
    }
    #featured-img {
        width: 50%;
    }
}

@media screen and (max-width:700px) {
    #archetypes-grid h5 {
        bottom: 12%;
        left: -19%;
        font-size: 90%;
    }
    #featuredImgContainer h4,
    #featuredImgContainer p {
        font-size: 3vw;
    }
    #featuredText {
        width: 100%;
    }
}

@media screen and (max-width:414px) {
    #featuredText {
        margin-top: 12%;
        width: 80%;
        margin-left: 5%;
    }
    #featuredImgContainer h4,
    #featuredImgContainer p {
        font-size: 4vw;
    }
    #featuredImg {
        width: 100%;
        height: 100%;
    }
    .vitelli-single {
        width: 40% !important;
    }
    .andrej-single img {
        width: 50% !important;
    }
}

@media screen and (max-width:375px) {
    #featuredText {
        margin-top: 12%;
        width: 80%;
        margin-left: 5%;
    }
    #archetypes-grid h5 {
        bottom: 12%;
        left: -20%;
        font-size: 70%;
    }
}

@media screen and (max-width:320px) {
    #featuredText {
        margin-top: 12%;
        width: 80%;
        margin-left: 5%;
    }
    #archetypes-grid h5 {
        bottom: 12%;
        left: -17%;
        font-size: 70%;
    }
    #featured-text {
        color: red;
    }
}

@media screen and (min-width: 1366px) {
    #topBlogPage {
        width: 86%;
        height: 90vh;
    }
    #bottomContainerText1 {
        position: absolute;
        right: 55%;
        bottom: 1%;
    }
    #bottomContainerText2 {
        position: absolute;
        right: 6%;
        bottom: 1%;
    }
    #descriptionsContainer {
        gap: -15%;
    }
    #bottomContainer {
        position: relative;
    }
    #bottomContainerImg {
        /* width: 80%; */
        /* height: 70%; */
        object-fit: contain;
        object-position: center;
    }
    #bottomContainer p {
        color: white
    }
}

#bottomContainerImg:hover {
    cursor: pointer;
}

#paddingAd {
    padding-top: 3%;
    padding-bottom: 3%;
}